import React from 'react'
import { Row, Col, Button, ButtonGroup, ListGroup } from 'react-bootstrap'

import DeleteButtonClickConfirm from '../lib/DeleteButtonClickConfirm'

import Asset from '../../models/Asset'
import FileUploader from '../lib/FileUploader'

interface Props {
  assets: Asset[]
  uploading: boolean
  sending: boolean

  upload: (file: File) => void
  moveAsset: (asset: Asset, offset: number) => void
  deleteAsset: (id: string) => void
}

export default function AssetsList(props: Props) {
  const { assets, sending, uploading, upload, moveAsset, deleteAsset } = props

  const uploadAssetButton = (
    <div className="text-right">
      {
        uploading ?
          <p className="font-italic">Uploading...</p>
          :
          <FileUploader upload={upload}>
            <Button disabled={sending} size="sm">Upload new track</Button>
          </FileUploader>
      }
    </div>
  )

  if (0 === assets.length) {
    return <><p className="font-italic">No track has been uploaded yet</p>{uploadAssetButton}</>
  }

  const items = assets.map((asset, index) => {
    return (
      <ListGroup.Item key={`asset-entry-${asset.id}`}>
        <Row>
          <Col xs={6}>
            ({index + 1}) <small><a href={asset.mediaUrl} target="_blank" rel="noopener noreferrer">{asset.filename || '???'}</a></small>
            <br />
            <small><a href={asset.hlsUrl} target="_blank" rel="noopener noreferrer">HLS url</a></small>
          </Col>
          <Col xs={6} className="text-right">
            <ButtonGroup>
              <Button onClick={() => moveAsset(asset, -1)} disabled={sending || 1 === asset.position } variant="secondary" size="sm"><span className="oi oi-arrow-thick-top"></span></Button>
              <Button onClick={() => moveAsset(asset, 1)} disabled={sending || assets.length === asset.position} variant="secondary" size="sm"><span className="oi oi-arrow-thick-bottom"></span></Button>
              <DeleteButtonClickConfirm
                label={<span className="oi oi-delete"></span>}
                labelConfirm={<span className="oi oi-delete"></span>}
                size="sm" action={() => deleteAsset(asset.id)} disabled={sending} />
            </ButtonGroup>
          </Col>
        </Row>
      </ListGroup.Item>
    )
  })

  return (
    <>
      <ListGroup className="mb-4">{items}</ListGroup>
      {uploadAssetButton}
    </>
  )
}
