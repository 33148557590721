import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import BooksList from '../containers/books/BooksList'
import BookDetails from '../containers/books/BookDetails'
import ChapterDetails from '../containers/chapters/ChapterDetails'

import CurrentUser from '../models/CurrentUser'

interface Props {
  currentUser: CurrentUser
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 2),
  },
}))

export default function HomeBackoffice(props: Props) {
  const classes = useStyles()

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          Audio books
        </Typography>
        <Typography component="div">
          <BooksList />
        </Typography>
      </Paper>

      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          Book details
        </Typography>
        <Typography component="div">
          <BookDetails />
        </Typography>
      </Paper>

      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          Chapter details
        </Typography>
        <Typography component="div">
          <ChapterDetails />
        </Typography>
      </Paper>
    </div>
  )
}
