import React, { Component } from 'react'
import { Form, ButtonGroup, Button, Card } from 'react-bootstrap'
import cloneDeep from 'lodash/cloneDeep'

import FormInputErrors, { hasErrors } from '../lib/FormInputErrors'

import Author from '../../models/Author'

export interface DataProps {
  sending: boolean
  errors: Errors
}

interface Props extends DataProps {
  save: (author: Author) => void
  cancelEdit: () => void
}

interface Errors {
  firstName?: string[]
  lastName?: string[]
}

interface State {
  author: Author
}

export default class NewAuthorForm extends Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props, state)

    this.state = { author: { id: '', firstName: '', lastName: '' } }

    this.save = this.save.bind(this)
    this.setValue = this.setValue.bind(this)
  }

  save() {
    this.props.save(this.state.author)
  }

  setValue(attribute: string, { target: { value } }: { target: { value: string } }) {
    const author: any = cloneDeep(this.state.author)
    author[attribute] = value
    this.setState({ author })
  }

  render() {
    const { sending, errors } = this.props
    const { author } = this.state

    return (
      <Card>
        <Card.Body>
          <Card.Title>Create new author</Card.Title>
          <Form.Group>
            <Form.Label>First name</Form.Label>
            <Form.Control
              isInvalid={hasErrors(errors, 'firstName')}
              disabled={sending}
              type="text" value={author.firstName || ''}
              onChange={event => this.setValue('firstName', event)} />
            <FormInputErrors elementId={`firstName-field`} errors={errors.firstName} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Last name</Form.Label>
            <Form.Control
              isInvalid={hasErrors(errors, 'lastName')}
              disabled={sending}
              type="text" value={author.lastName || ''}
              onChange={event => this.setValue('lastName', event)} />
            <FormInputErrors elementId={`lastName-field`} errors={errors.lastName} />
          </Form.Group>

          <Form.Row className="mt-4 justify-content-between">
            <ButtonGroup>
              <Button onClick={this.props.cancelEdit} disabled={sending} size="sm" variant="secondary">Cancel</Button>
              <Button onClick={this.save} disabled={sending} size="sm" variant="primary">Save</Button>
            </ButtonGroup>
          </Form.Row>
        </Card.Body>
      </Card>
    )
  }
}
