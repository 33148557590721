import { connect } from 'react-redux'

import ChapterFormComponent, { DataProps as ChapterFormProps} from '../../components/chapters/ChapterForm'

import { ChapterEditState } from '../../redux/state'
import { save, cancelEdit, deleteChapter } from '../../redux/actions/chapterActions'

const mapStateToProps = ({ chapterEdit }: { chapterEdit: ChapterEditState }): ChapterFormProps => {
  const chapter = chapterEdit.chapter
  if (!chapter) {
    throw new TypeError('Null reference to chapter')
  }

  return { ...chapterEdit, chapter }
}

export default connect(mapStateToProps, { save, cancelEdit, deleteChapter })(ChapterFormComponent)
