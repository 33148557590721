import React, { Component } from 'react'
import { Form, ButtonGroup, Button } from 'react-bootstrap'
import cloneDeep from 'lodash/cloneDeep'

import FormInputErrors, { hasErrors } from '../lib/FormInputErrors'
import DeleteButtonClickConfirm from '../lib/DeleteButtonClickConfirm'

import Chapter from '../../models/Chapter'

export interface DataProps {
  chapter: Chapter
  sending: boolean
  errors: Errors
}

interface Props extends DataProps {
  save: (model: Chapter) => void
  cancelEdit: () => void
  deleteChapter: (model: Chapter) => void
}

interface Errors {
  title?: string[]
}

interface State {
  chapterEditing: Chapter
}

export default class ChapterForm extends Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props, state)

    this.state = { chapterEditing: cloneDeep(this.props.chapter) }

    this.save = this.save.bind(this)
    this.setTitle = this.setTitle.bind(this)
  }

  save() {
    this.props.save(this.state.chapterEditing)
  }

  setTitle({ target: { value } }: { target: { value: string } }) {
    const chapterEditing = cloneDeep(this.state.chapterEditing)
    chapterEditing.title = value
    this.setState({ chapterEditing })
  }

  render() {
    const { sending, errors, chapter: { id }, deleteChapter } = this.props
    const { chapterEditing: { title } } = this.state
    const isNewRecord = !id

    return (
      <Form>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control isInvalid={hasErrors(errors, 'title')} disabled={sending} type="text" value={title} onChange={this.setTitle} />
          <FormInputErrors elementId={`chapter-${id}-title-field`} errors={errors.title} />
        </Form.Group>

        <Form.Row className="mt-4 justify-content-between">
          {
            !isNewRecord ?
              <DeleteButtonClickConfirm
                label="Delete chapter" labelConfirm="Click to confirm" size="sm"
                action={() => deleteChapter(this.props.chapter)}
                disabled={sending} />
              :
              <div />
          }

          <ButtonGroup>
            {
              !isNewRecord &&
                <Button onClick={this.props.cancelEdit} disabled={sending} size="sm" variant="secondary">Cancel</Button>
            }
            <Button onClick={this.save} disabled={sending} size="sm" variant="primary">Save</Button>
          </ButtonGroup>
        </Form.Row>
      </Form>
    )
  }
}
