import React from 'react'
import Upload from 'rc-upload'

interface Props {
  children: React.ReactNode | React.ReactNode[]
  upload: (file: File) => void
}

export default function FileUploader(props: Props) {
  const uploadProps = {
    multiple: false,
    accept: '.mp3',
    customRequest({ file }: { file: any }) {
      props.upload(file)
    }
  }

  return <Upload {...uploadProps}>{props.children}</Upload>
}
