import React, { Component } from 'react'
import { connect } from 'react-redux'

import PaginatedResponse from '../../models/PaginatedResponse'
import AudioBook from '../../models/AudioBook'

import AudioBooksListComponent, { SearchParams } from '../../components/books/BooksList'
import { loadAudioBooksList, openAudioBook, newAudioBook } from '../../redux/actions/audioBookActions'
import { StoreState } from '../../redux/state'

interface Props {
  loading: boolean
  list: PaginatedResponse<AudioBook>

  loadAudioBooksList: (params: SearchParams) => void
  openAudioBook: (book: AudioBook) => void
  newAudioBook: () => void
}

class BooksList extends Component<Props> {
  componentDidMount() {
    this.props.loadAudioBooksList({})
  }

  render() {
    const {
      loading, list, openAudioBook,
      newAudioBook, loadAudioBooksList
    } = this.props
    const openById = (id: string) => {
      const book: AudioBook | undefined = list.entries.find(b => b.id === id)
      if (book) { openAudioBook(book) }
    }

    return <AudioBooksListComponent
      loading={loading}
      list={list}
      openAudioBook={openById}
      newAudioBook={newAudioBook}
      searchBooks={loadAudioBooksList} />
  }
}

const mapStateToProps = (store: StoreState) => { return store.audioBooksList }

export default connect(mapStateToProps, { loadAudioBooksList, openAudioBook, newAudioBook })(BooksList)
