import {
  ASSET_UPLOAD_START,
  ASSET_UPLOAD_DONE,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  AssetSaveAction,

  ASSET_DELETE_START,
  ASSET_DELETE_DONE,
  AssetDeleteAction,

  CHAPTER_RELOAD,
  ChapterReload
} from '../actionTypes'

import { service as assetService } from '../../services/AssetService'
import { service as chapterService } from '../../services/ChapterService'
import Asset from '../../models/Asset'
import Chapter from '../../models/Chapter'

export function upload(chapterId: string, file: File): (dispatch: (action: AssetSaveAction | ChapterReload) => void) => void {
  return async dispatch => {
    dispatch({ type: ASSET_UPLOAD_START })

    await assetService.upload(chapterId, file)
    const chapter: Chapter = await chapterService.getChapter(chapterId)

    dispatch({ type: ASSET_UPLOAD_DONE })
    dispatch({ type: CHAPTER_RELOAD, payload: chapter })
  }
}

export function moveAsset(asset: Asset, offset: number): (dispatch: (action: AssetSaveAction | ChapterReload) => void) => void {
  return async dispatch => {
    dispatch({ type: ASSET_SAVE_START })

    const updateData: Asset = { ...asset, position: asset.position + offset }
    const updatedModel: Asset = await assetService.update(updateData)

    const chapter: Chapter = await chapterService.getChapter(asset.chapterId)

    dispatch({ type: ASSET_SAVE_DONE, payload: updatedModel })
    dispatch({ type: CHAPTER_RELOAD, payload: chapter })
  }
}

export function deleteAsset(id: string, chapterId: string): (dispatch: (action: AssetDeleteAction | ChapterReload) => void) => void {
  return async dispatch => {
    dispatch({ type: ASSET_DELETE_START, payload: { id } })

    await assetService.deleteModel(id)
    const chapter: Chapter = await chapterService.getChapter(chapterId)

    dispatch({ type: ASSET_DELETE_DONE, payload: { id } })
    dispatch({ type: CHAPTER_RELOAD, payload: chapter })
  }
}
