import { connect } from 'react-redux'

import BookFormComponent, { DataProps as BookFormProps} from '../../components/books/BookForm'

import { AudioBookEditState } from '../../redux/state'
import { save, cancelEdit, deleteAudioBook } from '../../redux/actions/audioBookActions'
import { build as buildAudioBook } from '../../models/AudioBook'

const mapStateToProps = ({ audioBookEdit }: { audioBookEdit: AudioBookEditState }): BookFormProps => {
  const audioBook = audioBookEdit.audioBook || buildAudioBook()

  return { ...audioBookEdit, audioBook }
}

export default connect(mapStateToProps, { save, cancelEdit, deleteAudioBook })(BookFormComponent)
