import React, { Component } from 'react'
import { Dropdown, ListGroup, Button } from 'react-bootstrap'
import clone from 'lodash/clone'
import filter from 'lodash/filter'

import NewAuthorForm from './NewAuthorForm'
import AuthorsDropdownSelect from './AuthorsDropdownSelect'
import Author from '../../models/Author'

export interface DataProps {
  editing: boolean
  sending: boolean
  errors: any
}

interface Props extends DataProps {
  selectedAuthors: Author[]

  setAuthors: (authors: Author[]) => void

  cancelEdit: () => void
  edit: () => void
  save: (author: Author) => void
}

export default class AuthorsForm extends Component<Props> {
  constructor(props: Props) {
    super(props)

    this.addAuthor = this.addAuthor.bind(this)
    this.removeAuthor = this.removeAuthor.bind(this)
  }

  addAuthor(author: Author) {
    const authors: Author[] = clone(this.props.selectedAuthors)
    authors.push(author)
    this.props.setAuthors(authors)
  }

  removeAuthor(author: Author) {
    const authors: Author[] = filter(this.props.selectedAuthors, item => item.id !== author.id)
    this.props.setAuthors(authors)
  }

  render() {
    return(
      <>
        { this.props.editing ?
            <NewAuthorForm
              sending={this.props.sending}
              errors={this.props.errors}
              save={this.props.save}
              cancelEdit={this.props.cancelEdit}
              />
            :
            <Dropdown className="mb-3">
              <Dropdown.Toggle>Add author</Dropdown.Toggle>
              <AuthorsDropdownSelect
                  selectedAuthors={this.props.selectedAuthors}
                  addAuthor={this.addAuthor}>
                <Dropdown.Item>
                  <Button onClick={this.props.edit}>Create new author</Button>
                </Dropdown.Item>
              </AuthorsDropdownSelect>
            </Dropdown>
        }

        <ListGroup className="mt-4">
          {
            this.props.selectedAuthors.map(author => {
              return (
                <ListGroup.Item key={`selected-auth-${author.id}`}>
                  <Button size="sm" variant="link" onClick={() => this.removeAuthor(author)}><span className="oi oi-delete"></span></Button>
                  {author.firstName} {author.lastName}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
    )
  }
}
