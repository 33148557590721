import {
  LOGIN_START,
  LOGIN_DONE,
  LOGIN_FAILED,
  LoginAction
} from '../actionTypes'
import { LoginState } from '../state'

const initialState: LoginState = { sending: false, failed: false, succeeded: false }

export default function reducer(state: LoginState = initialState, action: LoginAction) {
  switch (action.type) {

    case LOGIN_START: {
      return { ...state, sending: true, failed: false }
    }

    case LOGIN_FAILED: {
      return { ...state, sending: false, failed: true, succeeded: false }
    }

    case LOGIN_DONE: {
      return { ...state, sending: false, failed: false, succeeded: true }
    }

    default:
      return state
  }
}
