import {
  AUDIO_BOOKS_LIST_LOAD_START,
  AUDIO_BOOKS_LIST_LOAD_DONE,
  AudioBooksListAction,

  AUDIO_BOOK_OPEN,
  AudioBookOpen,

  AUDIO_BOOK_EDIT,
  AudioBookEdit,

  AUDIO_BOOK_SAVE_START,
  AUDIO_BOOK_SAVE_DONE,
  AUDIO_BOOK_SAVE_FAILED,
  AudioBookSaveAction,

  AUDIO_BOOK_DELETE_START,
  AUDIO_BOOK_DELETE_DONE,
  AudioBookDeleteAction
} from '../actionTypes'

import { service as audioBookService, SearchParams } from '../../services/AudioBookService'
import PaginatedResponse from '../../models/PaginatedResponse'
import AudioBook, { build as buildAudioBook } from '../../models/AudioBook'

export function loadAudioBooksList(params?: SearchParams): (dispatch: (action: AudioBooksListAction) => void) => void {
  
  return async dispatch => {
    dispatch({ type: AUDIO_BOOKS_LIST_LOAD_START })

    const list: PaginatedResponse<AudioBook> = await audioBookService.search(params || {})
    dispatch({ type: AUDIO_BOOKS_LIST_LOAD_DONE, payload: list })
  }
}

export function openAudioBook(payload: AudioBook): (dispatch: (action: AudioBookOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: AUDIO_BOOK_OPEN, payload })
  }
}

export function newAudioBook(): (dispatch: (action: AudioBookOpen | AudioBookEdit) => void) => void {
  return async dispatch => {
    const newBook: AudioBook = buildAudioBook()
    dispatch({ type: AUDIO_BOOK_OPEN, payload: newBook })
    dispatch({ type: AUDIO_BOOK_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: AudioBookEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: AUDIO_BOOK_EDIT, payload: true })
  }
}

export function cancelEdit(): (dispatch: (action: AudioBookEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: AUDIO_BOOK_EDIT, payload: false })
  }
}

export function save(audioBook: AudioBook): (dispatch: (action: AudioBookSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: AUDIO_BOOK_SAVE_START })
    try {
      const updatedModel: AudioBook = await audioBookService.save(audioBook)
      dispatch({ type: AUDIO_BOOK_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: AUDIO_BOOK_SAVE_FAILED, payload: err.data })
    }
  }
}

export function deleteAudioBook(id: string): (dispatch: (action: AudioBookDeleteAction) => void) => void {
  return async dispatch => {
    dispatch({ type: AUDIO_BOOK_DELETE_START, payload: { id } })
    try {
      await audioBookService.deleteModel(id)
      dispatch({ type: AUDIO_BOOK_DELETE_DONE, payload: { id } })
    } catch (err) {}
  }
}
