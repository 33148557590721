import {
  AUTHOR_SAVE_START,
  AUTHOR_SAVE_DONE,
  AUTHOR_SAVE_FAILED,
  AuthorSaveAction,

  AUTHOR_NEW,
  AuthorNew
} from '../actionTypes'
import { AuthorEditState } from '../state'

const initialState: AuthorEditState = {
  sending: false, editing: false, errors: {}, saveSucceeded: false
}

export default function reducer(
    state: AuthorEditState = initialState,
    action: AuthorSaveAction | AuthorNew) {

  switch (action.type) {

    case AUTHOR_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false }
    }

    case AUTHOR_SAVE_DONE: {
      return {
        ...state, sending: false,
        errors: {}, author: action.payload,
        editing: false,
        saveSucceeded: true
      }
    }

    case AUTHOR_SAVE_FAILED: {
      return {
        ...state, sending: false,
        errors: action.payload, saveSucceeded: false
      }
    }

    case AUTHOR_NEW: {
      return { ...state, editing: action.payload }
    }

    default:
      return state
  }
}
