import { AxiosRequestConfig } from 'axios'

import HttpClientService, { HttpClientResponse } from './HttpClientService'
import Asset from '../models/Asset'

export default class AssetService extends HttpClientService {

  async list(chapterId: string): Promise<Asset[]> {
    const request: AxiosRequestConfig = {
      method: 'GET',
      url: '/v1/assets',
      params: { chapterId }
    }

    const response: HttpClientResponse<Asset[]> = await this.doRequest(request)
    console.log(response.data);
    
    return response.data
  }

  async upload(chapterId: string, file: File): Promise<Asset> {
    const response: HttpClientResponse<Asset> = await this.doRequest({
      method: 'POST',
      url: '/v1/assets',
      data: { chapterId, file },
      transformRequest: ({ chapterId, file } ) => {
        const formData = new FormData()
        formData.append('asset[chapter_id]', chapterId)
        formData.append('asset[media]', file)

        return formData
      }
    })
    return response.data
  }

  async update(asset: Asset): Promise<Asset> {
    const response: HttpClientResponse<Asset> = await this.doRequest({
      method: 'PATCH',
      url: `/v1/assets/${asset.id}`,
      data: { asset: { ...asset, id: undefined, filename: undefined } }
    })
    return response.data
  }

  async deleteModel(id: string): Promise<boolean> {
    const request: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v1/assets/${id}`
    }

    await this.doRequest(request)
    return true
  }
}

export const service = new AssetService()
