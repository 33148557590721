import { connect } from 'react-redux'

import AuthorsFormComponent, { DataProps } from '../../components/books/AuthorsForm'

import { AuthorEditState } from '../../redux/state'
import { save, edit, cancelEdit } from '../../redux/actions/authorActions'

const mapStateToProps = ({ authorEdit }: { authorEdit: AuthorEditState }, ownProps: any): DataProps => {
  return { ...authorEdit, ...ownProps }
}

export default connect(mapStateToProps, { save, edit, cancelEdit })(AuthorsFormComponent)
