import clone from 'lodash/clone'
import findIndex from 'lodash/findIndex'

import {
  AUDIO_BOOKS_LIST_LOAD_START,
  AUDIO_BOOKS_LIST_LOAD_DONE,
  AudioBooksListAction,

  AUDIO_BOOK_SAVE_DONE,
  AudioBookSaveAction,

  AUDIO_BOOK_DELETE_DONE,
  AudioBookDeleteAction
} from '../actionTypes'
import { AudioBooksListState } from '../state'
import PaginatedResponse, { empty } from '../../models/PaginatedResponse'
import AudioBook from '../../models/AudioBook'

const initialState: AudioBooksListState = { loading: false, list: empty }

export default function reducer(
    state: AudioBooksListState = initialState,
    action: AudioBooksListAction | AudioBookSaveAction | AudioBookDeleteAction) {
  switch (action.type) {

    case AUDIO_BOOKS_LIST_LOAD_START: {
      return { ...state, loading: true }
    }

    case AUDIO_BOOKS_LIST_LOAD_DONE: {
      return { ...state, loading: false, list: action.payload }
    }

    case AUDIO_BOOK_SAVE_DONE: {
      const entries: AudioBook[] = clone(state.list.entries)
      const updated: AudioBook = action.payload

      const index = findIndex(entries, entry => entry.id === updated.id)
      if (index >= 0) {
        entries[index] = updated
      } else {
        entries.push(updated)
      }
      const list: PaginatedResponse<AudioBook> = { ...state.list, entries }
      return { ...state, list }
    }

    case AUDIO_BOOK_DELETE_DONE: {
      const { id } = action.payload
      const entries: AudioBook[] = clone(state.list.entries)

      const index = findIndex(entries, entry => entry.id === id)
      if (index >= 0) {
        entries.splice(index, 1)
      }
      const list: PaginatedResponse<AudioBook> = { ...state.list, entries }
      return { ...state, list }
    }

    default:
      return state
  }
}
