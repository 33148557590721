import CurrentUser from '../models/CurrentUser'
import PaginatedResponse from '../models/PaginatedResponse'
import AudioBook from '../models/AudioBook'
import Chapter from '../models/Chapter'
import Asset from '../models/Asset'
import Author from '../models/Author'

// login
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_DONE = 'LOGIN_DONE'
export const LOGIN_FAILED = 'LOGIN_FAILED'

type LOGIN_START_TYPE = typeof LOGIN_START
type LOGIN_DONE_TYPE = typeof LOGIN_DONE
type LOGIN_FAILED_TYPE = typeof LOGIN_FAILED

interface LoginStart {
  type: LOGIN_START_TYPE
}

interface LoginDone {
  type: LOGIN_DONE_TYPE
}

interface LoginFailed {
  type: LOGIN_FAILED_TYPE
}

export type LoginAction = LoginStart | LoginDone | LoginFailed

// current user
export const CURRENT_USER_LOAD_START = 'CURRENT_USER_LOAD_START'
export const CURRENT_USER_LOAD_DONE = 'CURRENT_USER_LOAD_DONE'
export const CURRENT_USER_LOAD_FAILED = 'CURRENT_USER_LOAD_FAILED'

type CURRENT_USER_LOAD_START_TYPE = typeof CURRENT_USER_LOAD_START
type CURRENT_USER_LOAD_DONE_TYPE = typeof CURRENT_USER_LOAD_DONE
type CURRENT_USER_LOAD_FAILED_TYPE = typeof CURRENT_USER_LOAD_FAILED

interface CurrentUserLoadStart {
  type: CURRENT_USER_LOAD_START_TYPE
}

interface CurrentUserLoadDone {
  type: CURRENT_USER_LOAD_DONE_TYPE
  payload: CurrentUser
}

interface CurrentUserLoadFailed {
  type: CURRENT_USER_LOAD_FAILED_TYPE
}

export type CurrentUserAction = CurrentUserLoadStart | CurrentUserLoadDone | CurrentUserLoadFailed

// AudioBook list
export const AUDIO_BOOKS_LIST_LOAD_START = 'AUDIO_BOOKS_LIST_LOAD_START'
export const AUDIO_BOOKS_LIST_LOAD_DONE = 'AUDIO_BOOKS_LIST_LOAD_DONE'

type AUDIO_BOOKS_LIST_LOAD_START_TYPE = typeof AUDIO_BOOKS_LIST_LOAD_START
type AUDIO_BOOKS_LIST_LOAD_DONE_TYPE = typeof AUDIO_BOOKS_LIST_LOAD_DONE

interface AudioBooksListLoadStart {
  type: AUDIO_BOOKS_LIST_LOAD_START_TYPE
}

interface AudioBooksListLoadDone {
  type: AUDIO_BOOKS_LIST_LOAD_DONE_TYPE
  payload: PaginatedResponse<AudioBook>
}

export type AudioBooksListAction = AudioBooksListLoadStart | AudioBooksListLoadDone

// AudioBook open
export const AUDIO_BOOK_OPEN = 'AUDIO_BOOK_OPEN'
type AUDIO_BOOK_OPEN_TYPE = typeof AUDIO_BOOK_OPEN

export interface AudioBookOpen {
  type: AUDIO_BOOK_OPEN_TYPE,
  payload: AudioBook
}

// AudioBook edit
export const AUDIO_BOOK_EDIT = 'AUDIO_BOOK_EDIT'
type AUDIO_BOOK_EDIT_TYPE = typeof AUDIO_BOOK_EDIT

export interface AudioBookEdit {
  type: AUDIO_BOOK_EDIT_TYPE,
  payload: boolean
}

// AudioBook save
export const AUDIO_BOOK_SAVE_START = 'AUDIO_BOOK_SAVE_START'
type AUDIO_BOOK_SAVE_START_TYPE = typeof AUDIO_BOOK_SAVE_START
export const AUDIO_BOOK_SAVE_DONE = 'AUDIO_BOOK_SAVE_DONE'
type AUDIO_BOOK_SAVE_DONE_TYPE = typeof AUDIO_BOOK_SAVE_DONE
export const AUDIO_BOOK_SAVE_FAILED = 'AUDIO_BOOK_SAVE_FAILED'
type AUDIO_BOOK_SAVE_FAILED_TYPE = typeof AUDIO_BOOK_SAVE_FAILED

interface AudioBookSaveStart {
  type: AUDIO_BOOK_SAVE_START_TYPE
}

interface AudioBookSaveDone {
  type: AUDIO_BOOK_SAVE_DONE_TYPE
  payload: AudioBook
}

interface AudioBookSaveFailed {
  type: AUDIO_BOOK_SAVE_FAILED_TYPE,
  payload: object
}

export type AudioBookSaveAction = AudioBookSaveStart | AudioBookSaveDone | AudioBookSaveFailed

// AudioBook delete
export const AUDIO_BOOK_DELETE_START = 'AUDIO_BOOK_DELETE_START'
type AUDIO_BOOK_DELETE_START_TYPE = typeof AUDIO_BOOK_DELETE_START
export const AUDIO_BOOK_DELETE_DONE = 'AUDIO_BOOK_DELETE_DONE'
type AUDIO_BOOK_DELETE_DONE_TYPE = typeof AUDIO_BOOK_DELETE_DONE

interface AudioBookDeleteStart {
  type: AUDIO_BOOK_DELETE_START_TYPE
  payload: { id: string }
}

interface AudioBookDeleteDone {
  type: AUDIO_BOOK_DELETE_DONE_TYPE
  payload: { id: string }
}

export type AudioBookDeleteAction = AudioBookDeleteStart | AudioBookDeleteDone

// Chapter list
export const CHAPTERS_LOAD_START = 'CHAPTERS_LOAD_START'
export const CHAPTERS_LOAD_DONE = 'CHAPTERS_LOAD_DONE'

type CHAPTERS_LOAD_START_TYPE = typeof CHAPTERS_LOAD_START
type CHAPTERS_LOAD_DONE_TYPE = typeof CHAPTERS_LOAD_DONE

interface ChaptersLoadStart {
  type: CHAPTERS_LOAD_START_TYPE
}

interface ChaptersLoadDone {
  type: CHAPTERS_LOAD_DONE_TYPE
  payload: Chapter[]
}

export type ChaptersAction = ChaptersLoadStart | ChaptersLoadDone

// Chapter open
export const CHAPTER_OPEN = 'CHAPTER_OPEN'
type CHAPTER_OPEN_TYPE = typeof CHAPTER_OPEN

export interface ChapterOpen {
  type: CHAPTER_OPEN_TYPE,
  payload: Chapter
}

// Chapter edit
export const CHAPTER_EDIT = 'CHAPTER_EDIT'
type CHAPTER_EDIT_TYPE = typeof CHAPTER_EDIT

export interface ChapterEdit {
  type: CHAPTER_EDIT_TYPE,
  payload: boolean
}

// Chapter reload
export const CHAPTER_RELOAD = 'CHAPTER_RELOAD'
type CHAPTER_RELOAD_TYPE = typeof CHAPTER_RELOAD

export interface ChapterReload {
  type: CHAPTER_RELOAD_TYPE,
  payload: Chapter
}

// Chapter save
export const CHAPTER_SAVE_START = 'CHAPTER_SAVE_START'
type CHAPTER_SAVE_START_TYPE = typeof CHAPTER_SAVE_START
export const CHAPTER_SAVE_DONE = 'CHAPTER_SAVE_DONE'
type CHAPTER_SAVE_DONE_TYPE = typeof CHAPTER_SAVE_DONE
export const CHAPTER_SAVE_FAILED = 'CHAPTER_SAVE_FAILED'
type CHAPTER_SAVE_FAILED_TYPE = typeof CHAPTER_SAVE_FAILED

interface ChapterSaveStart {
  type: CHAPTER_SAVE_START_TYPE
}

interface ChapterSaveDone {
  type: CHAPTER_SAVE_DONE_TYPE
  payload: Chapter
}

interface ChapterSaveFailed {
  type: CHAPTER_SAVE_FAILED_TYPE,
  payload: object
}

export type ChapterSaveAction = ChapterSaveStart | ChapterSaveDone | ChapterSaveFailed

// Chapter delete
export const CHAPTER_DELETE_START = 'CHAPTER_DELETE_START'
type CHAPTER_DELETE_START_TYPE = typeof CHAPTER_DELETE_START
export const CHAPTER_DELETE_DONE = 'CHAPTER_DELETE_DONE'
type CHAPTER_DELETE_DONE_TYPE = typeof CHAPTER_DELETE_DONE

interface ChapterDeleteStart {
  type: CHAPTER_DELETE_START_TYPE
  payload: { id: string }
}

interface ChapterDeleteDone {
  type: CHAPTER_DELETE_DONE_TYPE
  payload: { id: string }
}

export type ChapterDeleteAction = ChapterDeleteStart | ChapterDeleteDone

// Asset save/upload
export const ASSET_UPLOAD_START = 'ASSET_UPLOAD_START'
type ASSET_UPLOAD_START_TYPE = typeof ASSET_UPLOAD_START
export const ASSET_UPLOAD_DONE = 'ASSET_UPLOAD_DONE'
type ASSET_UPLOAD_DONE_TYPE = typeof ASSET_UPLOAD_DONE
export const ASSET_SAVE_START = 'ASSET_SAVE_START'
type ASSET_SAVE_START_TYPE = typeof ASSET_SAVE_START
export const ASSET_SAVE_DONE = 'ASSET_SAVE_DONE'
type ASSET_SAVE_DONE_TYPE = typeof ASSET_SAVE_DONE
export const ASSET_SAVE_FAILED = 'ASSET_SAVE_FAILED'
type ASSET_SAVE_FAILED_TYPE = typeof ASSET_SAVE_FAILED

interface AssetUploadStart {
  type: ASSET_UPLOAD_START_TYPE
}

interface AssetUploadDone {
  type: ASSET_UPLOAD_DONE_TYPE
}

interface AssetSaveStart {
  type: ASSET_SAVE_START_TYPE
}

interface AssetSaveDone {
  type: ASSET_SAVE_DONE_TYPE
  payload: Asset
}

interface AssetSaveFailed {
  type: ASSET_SAVE_FAILED_TYPE,
  payload: object
}

export type AssetSaveAction = AssetUploadStart | AssetUploadDone | AssetSaveStart | AssetSaveDone | AssetSaveFailed

// Asset delete
export const ASSET_DELETE_START = 'ASSET_DELETE_START'
type ASSET_DELETE_START_TYPE = typeof ASSET_DELETE_START
export const ASSET_DELETE_DONE = 'ASSET_DELETE_DONE'
type ASSET_DELETE_DONE_TYPE = typeof ASSET_DELETE_DONE

interface AssetDeleteStart {
  type: ASSET_DELETE_START_TYPE
  payload: { id: string }
}

interface AssetDeleteDone {
  type: ASSET_DELETE_DONE_TYPE
  payload: { id: string }
}

export type AssetDeleteAction = AssetDeleteStart | AssetDeleteDone


// Author save
export const AUTHOR_SAVE_START = 'AUTHOR_SAVE_START'
type AUTHOR_SAVE_START_TYPE = typeof AUTHOR_SAVE_START
export const AUTHOR_SAVE_DONE = 'AUTHOR_SAVE_DONE'
type AUTHOR_SAVE_DONE_TYPE = typeof AUTHOR_SAVE_DONE
export const AUTHOR_SAVE_FAILED = 'AUTHOR_SAVE_FAILED'
type AUTHOR_SAVE_FAILED_TYPE = typeof AUTHOR_SAVE_FAILED

interface AuthorSaveStart {
  type: AUTHOR_SAVE_START_TYPE
}

interface AuthorSaveDone {
  type: AUTHOR_SAVE_DONE_TYPE
  payload: Author
}

interface AuthorSaveFailed {
  type: AUTHOR_SAVE_FAILED_TYPE,
  payload: object
}

export type AuthorSaveAction = AuthorSaveStart | AuthorSaveDone | AuthorSaveFailed


// Author new
export const AUTHOR_NEW = 'AUTHOR_NEW'
type AUTHOR_NEW_TYPE = typeof AUTHOR_NEW

export interface AuthorNew {
  type: AUTHOR_NEW_TYPE,
  payload: boolean
}
