import currentUser from './currentUserReducer'
import login from './loginReducer'
import audioBooksList from './audioBooksListReducer'
import editAudioBook from './editAudioBookReducer'
import chaptersList from './chaptersListReducer'
import editChapter from './editChapterReducer'
import editAuthor from './editAuthorReducer'

export default {
  currentUser,
  login,
  audioBooksList, audioBookEdit: editAudioBook,
  chaptersList, chapterEdit: editChapter,
  authorEdit: editAuthor
}
