import Author from './Author'

export default interface AudioBook extends Record<string, any> {
  id: string
  title: string
  kind: string
  productId: string
  isbn?: string
  rssFeedLink?: string
  summary?: string
  category?: string
  director?: string
  duration?: string
  release?: string
  translator?: string
  bookEdition?: string
  publishingDate?: string
  authors: Author[]
}

export function build() {
  return {
    id: '',
    title: '',
    kind: '',
    productId: '',
    authors: []
  }
}
