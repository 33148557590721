import Asset from './Asset'

export default interface Chapter {
  id: string
  audioBookId: string
  title: string
  position: number
  assets: Asset[]
}

export function build(audioBookId: string) {
  return {
    id: '',
    audioBookId,
    title: '',
    position: 0,
    assets: []
  }
}
