import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import store from "../../redux/store"

export default class HttpClientService {

  http: AxiosInstance

  constructor(http = axios) {
    this.http = http.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: { 'Accept': 'application/json' }
    })
  }

  async doRequest<T>(params: AxiosRequestConfig): Promise<HttpClientResponse<T>> {
    const { auth: { authToken } } = store.getState()

    if (authToken) {
      params.headers = { ...params.headers, Authorization: `Bearer ${authToken}` }
    }
    try {
      const response: AxiosResponse<T> = await this.http(params)
      return new HttpClientResponse(response)
    } catch (err) {
      const error = err as AxiosError
      throw new HttpClientError(error)
    }
  }
}

export class HttpClientResponse<T> {
  statusCode: number
  data: T

  constructor(response: AxiosResponse<T>) {
    this.statusCode = response.status
    this.data = response.data
  }
}

export class HttpClientError {
  statusCode: number
  errorPayload: any

  constructor(error: AxiosError<any>) {
    if (error.response) {
      this.statusCode = error.response.status
      this.errorPayload = error.response.data
    } else {
      this.statusCode = 999
      this.errorPayload = error.toJSON()
    }
  }

  get data(): any { return this.errorPayload }
}
