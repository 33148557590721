import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import adminUi from "../admin-ui/redux/reducers";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  ...adminUi
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
