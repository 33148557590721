import { AxiosRequestConfig } from 'axios'

import HttpClientService, { HttpClientResponse } from './HttpClientService'
import Author from '../models/Author'
import PaginatedResponse from '../models/PaginatedResponse'

export default class PersonService extends HttpClientService {

  async searchAuthors(search: string): Promise<PaginatedResponse<Author>> {
    const params: any = { type: 'Author' }
    if ('string' === typeof search && '' !== search) {
      params.search = search
    }

    const request: AxiosRequestConfig = {
      method: 'GET',
      url: '/v1/people',
      params
    }

    const response: HttpClientResponse<PaginatedResponse<Author>> = await this.doRequest(request)
    return response.data
  }

  async saveAuthor(model: Author): Promise<Author> {
    let request: AxiosRequestConfig
    const data = { ...model, type: 'Author', id: undefined }

    if (model.id) {
      request = {
        method: 'PATCH',
        url: `/v1/people/${model.id}`,
        data
      }
    } else {
      request = {
        method: 'POST',
        url: '/v1/people',
        data
      }
    }
    const response: HttpClientResponse<Author> = await this.doRequest(request)
    return response.data
  }
}

export const service = new PersonService()
