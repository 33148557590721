import {
  CURRENT_USER_LOAD_START,
  CURRENT_USER_LOAD_DONE,
  CURRENT_USER_LOAD_FAILED,
  CurrentUserAction
} from '../actionTypes'

import { CurrentUserState } from '../state'

const initialState: CurrentUserState = { loading: false }

export default function reducer(
    state: CurrentUserState = initialState,
    action: CurrentUserAction) {

  switch (action.type) {
    case CURRENT_USER_LOAD_START: {
      return { ...state, loading: true }
    }

    case CURRENT_USER_LOAD_DONE: {
      return { ...state, loading: false, authenticated: true, userData: action.payload }
    }

    case CURRENT_USER_LOAD_FAILED: {
      return { ...state, loading: false, authenticated: false, userData: undefined }
    }

    default:
      return state
  }
}
