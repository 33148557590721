import React, { Component } from 'react'
import { connect } from 'react-redux'

import Chapter from '../../models/Chapter'

import ChaptersListComponent from '../../components/chapters/ChaptersList'
import { loadChaptersList, openChapter, newChapter, moveChapter } from '../../redux/actions/chapterActions'
import { StoreState } from '../../redux/state'

interface OwnProps {
  audioBookId: string
}

interface Props extends OwnProps {
  loading: boolean
  sending: boolean
  chapters: Chapter[]

  loadChaptersList: () => void
  openChapter: (model: Chapter) => void
  newChapter: () => void
  moveChapter: (model: Chapter, offset: number) => void
}

class ChaptersList extends Component<Props> {
  componentDidMount() {
    this.props.loadChaptersList()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.audioBookId !== prevProps.audioBookId) {
      this.props.loadChaptersList()
    }
  }

  render() {
    const { loading, sending, chapters, openChapter, newChapter, moveChapter } = this.props
    return <ChaptersListComponent
      loading={loading} sending={sending} chapters={chapters}
      openChapter={openChapter} newChapter={newChapter} moveChapter={moveChapter} />
  }
}

const mapStateToProps = ({ chaptersList, chapterEdit: { sending } }: StoreState) => ({
  ...chaptersList, sending
})

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => {
  const { audioBookId } = ownProps

  return {
    loadChaptersList: () => loadChaptersList(audioBookId)(dispatch),
    openChapter: (model: Chapter) => openChapter(model)(dispatch),
    newChapter: () => newChapter(audioBookId)(dispatch),
    moveChapter: (model: Chapter, offset: number) => moveChapter(model, offset)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChaptersList)
