import { AxiosRequestConfig } from 'axios'

import HttpClientService, { HttpClientResponse } from './HttpClientService'
import PaginatedResponse from '../models/PaginatedResponse'
import AudioBook from '../models/AudioBook'

export interface SearchParams {
  page?: number
  search?: string
}

export default class AudioBookService extends HttpClientService {

  async search(params: SearchParams): Promise<PaginatedResponse<AudioBook>> {
    const request: AxiosRequestConfig = {
      method: 'GET',
      url: '/v1/books',
      params
    }
   
    
    const response: HttpClientResponse<PaginatedResponse<AudioBook>> = await this.doRequest(request)
    return response.data
  }

  async save(model: AudioBook): Promise<AudioBook> {
    let request: AxiosRequestConfig
    const data: any = { ...model, id: undefined }
    if (data.authors) {
      data.overrideAuthors = data.authors.map((a: any) => a.id)
      delete data.authors
    }

    if (model.id) {
      request = {
        method: 'PATCH',
        url: `/v1/books/${model.id}`,
        data: { audioBook: data }
      }
    } else {
      request = {
        method: 'POST',
        url: '/v1/books',
        data: { audioBook: data }
      }
    }

    const response: HttpClientResponse<AudioBook> = await this.doRequest(request)
    return response.data
  }

  async deleteModel(id: string): Promise<boolean> {
    const request: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v1/books/${id}`
    }

    await this.doRequest(request)
    return true
  }
}

export const service = new AudioBookService()
